import * as React from 'react';
import '../styles/global.scss';
import '../styles/normalize.scss';
import '../styles/typography.scss';
import '../styles/settings.scss';
import Headers from '../components/Headers/Headers';
import SiteInfoServices from '../components/SiteInfoServices/SiteInfoServices';
import { strony_wordpress_txt } from '../consts/texts';
import ServicesInfo from '../components/ServicesInfo/ServicesInfo';
import Prices from '../components/Prices/Prices';
import Footer from '../components/Footer/Footer';
import RootLayout from '../components/RootLayout/RootLayout';
import ScroolUpButton from '../components/ScroolUpButton/ScroolUpButton';
import ScroolToPriceButton from '../components/ScroolToPriceButton/ScroolToPriceButton';

const StronyWordpress = () => {
  const title_top = 'Professional';
  const title_middle = 'Websites';
  const title_bottom = 'Powered by WordPress';
  const subtitle =
    'I create modern, functional WordPress websites – easy to manage, quick to implement, and tailored to your business.';

  const siteTitle = 'WordPress Websites – Easy to Manage and Effective Solutions';
  const siteDescription =
    'I specialize in creating websites based on WordPress – a popular and flexible CMS. Responsive, optimized, and user-friendly solutions for businesses of all sizes.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/wordpress-websites/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/strony-wordpress/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers
        title_top={title_top}
        title_middle={title_middle}
        title_bottom={title_bottom}
        subtitle={subtitle}
        active="wordpress"
      />
      <ScroolUpButton />
      <ScroolToPriceButton />
      <SiteInfoServices
        title_first_column={strony_wordpress_txt.title_first_column}
        title_first_card={strony_wordpress_txt.title_first_card}
        title_second_card={strony_wordpress_txt.title_second_card}
        descryption_first_column={strony_wordpress_txt.descryption_first_column}
        card_descryption={strony_wordpress_txt.card_descryption}
        second_card_descryption={strony_wordpress_txt.second_card_descryption}
      />
      <ServicesInfo wordpress />
      <Prices wordpress />
      <Footer />
    </>
  );
};

export default StronyWordpress;
